import Cookies from 'js-cookie';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isNewMessage } from '../../utils/functions/discussion';
import Comment from '../Comment';
import DiscussionForm from '../Discussion/DiscussionForm';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';

export const CommentList = ({
  messages,
  discussion,
  answerForm,
  setAnswerForm,
  newMessageIds,
  color,
  theme
}) => {
  const [messagesLiked, setMessagesLiked] = useState([]);
  const { t } = useTranslation('common');

  const screens = useBreakpoint();

  // --------------- Cookies ------------------------ //
  useEffect(() => {
    if (Cookies.get('messageLiked')) {
      setMessagesLiked(JSON.parse(Cookies.get('messageLiked')));
    }
  }, []);
  return (
    <>
      {messages.map((message) => (
        <div>
          <Comment
            key={message.id}
            messageId={message.id}
            message={message}
            likes={message.likes}
            content={message.value}
            datetime={message.updatedAt || message.createdAt}
            shouldAllowLike={discussion.shouldAllowLike}
            shouldAllowAnswer={discussion.shouldAllowAnswer}
            setAnswerForm={setAnswerForm}
            messagesLiked={messagesLiked}
            setMessagesLiked={setMessagesLiked}
            isNewMessage={isNewMessage(message.id, newMessageIds)}
            t={t}
            color={color}
            isMobile={!screens.sm}
          />
          {answerForm === message.id && (
            <div className="discussionForm__container">
              <DiscussionForm
                data-cy="answerMessage"
                discussion={discussion}
                parentMessage={message.id}
                setAnswerForm={setAnswerForm}
                hideFormFields={true}
                cancelAnswer={() => setAnswerForm()}
                theme={theme}
                isAnswerForm
              />
            </div>
          )}
          {message?.answers?.length > 0 &&
            message.answers.map((answer) => (
              <Comment
                key={answer.id}
                isChild
                messageId={answer.id}
                message={answer}
                likes={answer.likes}
                content={answer.value}
                datetime={answer.updatedAt || answer.createdAt}
                shouldAllowLike={discussion.shouldAllowLike}
                messagesLiked={messagesLiked}
                setMessagesLiked={setMessagesLiked}
                color={color}
                isMobile={!screens.sm}
              />
            ))}
        </div>
      ))}
    </>
  );
};
