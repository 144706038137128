import React from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import relativeTime from 'dayjs/plugin/relativeTime';
import md5 from 'md5';
import { CrownOutlined } from '@ant-design/icons';
dayjs.extend(relativeTime);

export const getDisplayName = (message, t) => {
  if (message?.fromModerator) {
    if (message?.moderatorContent) {
      return message?.moderatorContent?.pseudo + ' (' + t('moderator') + ')';
    } else {
      return t('moderator');
    }
  } else {
    const displayKey = message?.fieldValues?.find(
      (item) => item.field.isDisplayKey
    );
    return displayKey?.value ? displayKey.value : t('Anonymous');
  }
};

export const getAvatar = (message, size = 60) => {
  let avatar = `https://${process.env.NEXT_PUBLIC_SCW_STORE_PREFIX}/default-images/userPlaceholder.png`;
  if (!message?.fieldValues.length) return avatar;
  const email = message.fieldValues.find(
    (item) => item.field?.formLabel?.label?.toLowerCase() === 'email'
  );
  if (email?.value) {
    avatar = `https://www.gravatar.com/avatar/${md5(
      email ? email.value : ''
    )}.jpg?s=${size}`;
  }
  return avatar;
};

export const getDate = (message, locale = 'fr') => {
  //if (!locale) return;
  dayjs.locale(locale); // use locale globally
  dayjs().locale(locale).format();
  return (
    // <Tooltip
    //   title={dayjs(message.updatedAt || message.createdAt).format(
    //     'DD MMM YYYY, H:mm'
    //   )}
    // >
    <span>{dayjs(message?.updatedAt || message?.createdAt).fromNow()}</span>
    // </Tooltip>
  );
};

export const sortByDate = (a, b) => {
  if (a.createdAt < b.createdAt) return 1;
  if (a.createdAt > b.createdAt) return -1;
  return 0;
};

export const sortByDateDesc = (a, b) => {
  if (a.createdAt < b.createdAt) return -1;
  if (a.createdAt > b.createdAt) return 1;
  return 0;
};

export const isNewMessage = (messageId, newMessageIds) => {
  return newMessageIds.some((id) => messageId === id);
};

export const scrollToLastMsg = (messageId = null) => {
  const anchor = document.getElementById('feedContainer');
  anchor.scrollTop = 0;
};

export const getFormInfos = (privacyType, hasMandatoryField) => {
  const anonAllowed = !(
    privacyType === 'inscription' ||
    privacyType === 'invitation' ||
    hasMandatoryField
  );

  return { anonAllowed };
};
