import { Empty } from 'antd';
import { useTranslation } from 'next-i18next';
import React, { useEffect, useMemo, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import { GetDiscussionPublicMessages } from '../../../utils/store/discussion';
import { NEW_MESSAGE_SUBSCRIPTION } from '../../../utils/subscription/discussion';

import { useRecoilState } from 'recoil';
import { notificationCountState } from '../../../recoil/notificationCount';
import { notificationShouldUpdateState } from '../../../recoil/notificationShouldUpdate';
import { sortByDate } from '../../../utils/functions/discussion';
import { CommentList } from '../../CommentList';
import LoaderSpin from '../../LoaderSpin';
import useUuid from '../../../utils/hooks/useUuid';

const DiscussionFeed = function ({
  discussion,
  answerForm,
  setAnswerForm,
  color,
  theme,
  webTvId,
  playlistId
}) {
  const { t } = useTranslation('common');
  const [isInit, setIsInit] = useState(false);
  const [infiniteIsDone, setInfiniteIsDone] = useState(false);
  const [cursor, setCursor] = useState();

  const [newMessageIds, setNewMessageIds] = useState([]);

  const [count, setCount] = useRecoilState(notificationCountState);
  const [shouldUpdate, setShouldUpdate] = useRecoilState(
    notificationShouldUpdateState
  );

  const uuid = useUuid();

  const handleCompleted = (data) => {
    const newCursor = data?.messageFeed?.cursor;
    if (newCursor) {
      setCursor(newCursor);
    } else {
      setInfiniteIsDone(true);
    }
  };

  const { messageFeed, loading, fetchMore, subscribeToMore } =
    GetDiscussionPublicMessages(
      discussion?.id,
      handleCompleted,
      playlistId,
      webTvId
    );

  const messages = useMemo(() => {
    if (!messageFeed?.messages.length) return [];
    return [...messageFeed.messages]
      .filter(
        (message) =>
          !message.isCensored &&
          !message.isPrivate &&
          message.isVisible &&
          !message.isArchived &&
          !message.isDeleted
      )
      .sort(sortByDate)
      .map((message) => {
        return {
          ...message,
          ...(message?.answers.length > 0 && {
            answers: message.answers.filter(
              (answer) =>
                !answer.isCensored && !answer.isPrivate && answer.isVisible
            )
          })
        };
      });
  }, [messageFeed]);

  // --------------- Dom event scroll and notification ------------------------ //

  const { ref: infiniteLoaderRef, inView: infiniteLoaderInView } = useInView({
    root: null,
    rootMargin: '100px',
    threshold: 0.1
  });

  const { ref: bottomRef, inView: bottomInView } = useInView({
    root: null,
    rootMargin: '20px',
    threshold: 0.1
  });

  const handleNewMessage = (incomingMessage) => {
    setNewMessageIds((prev) => [...prev, incomingMessage.id]);
    if (
      shouldUpdate &&
      uuid !== incomingMessage.uuid &&
      !incomingMessage.isCensored &&
      incomingMessage.isVisible
    ) {
      setCount((prev) => prev + 1);
    }
  };

  useEffect(() => {
    if (!newMessageIds.length) return;
    const newMessageIdsTmp = [...newMessageIds];
    newMessageIdsTmp.shift();
    setTimeout(() => {
      setNewMessageIds(newMessageIdsTmp);
    }, 100);
  }, [newMessageIds]);

  useEffect(() => {
    if (!messageFeed?.messages?.length) return;
    if (!isInit) {
      setTimeout(() => {
        setIsInit(true);
      }, 1500);
    }
  }, [messageFeed]);

  useEffect(() => {
    const update = !bottomInView || !!answerForm;
    setShouldUpdate(update);
  }, [answerForm, bottomInView]);

  useEffect(() => {
    if (bottomInView && count > 0) {
      setCount(0);
    }
  }, [count, bottomInView]);

  // --------------- Data Fetching ------------------------ //
  useEffect(() => {
    if (!infiniteLoaderInView || loading || !isInit) return;
    fetchMore({
      variables: {
        id: discussion.id,
        cursor
      },
    });
  }, [infiniteLoaderInView, loading]);

  useEffect(() => {
    if (!subscribeToMore || loading) return;
    const unsubscribe = subscribeToMore({
      document: NEW_MESSAGE_SUBSCRIPTION,
      variables: {
        discussionId: discussion.id
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        const { mutation, data: incomingMessage } =
          subscriptionData.data.message;
        // if (incomingMessage.isCensored) return prev;
        if (mutation === 'CREATED') {
          handleNewMessage(incomingMessage);
        }
        return {
          messageFeed: {
            ...prev?.messageFeed,
            ...(!prev?.messageFeedId && {
              messageFeedId: discussion.id
            }),
            ...(!prev?.messageFeed?.cursor && {
              cursor: null
            }),
            messages: [{ __typename: 'Message', ...incomingMessage }]
          }
        };
      }
    });

    return () => {
      unsubscribe();
    };
  }, [subscribeToMore, loading]);

  if (loading) return <LoaderSpin />;

  if (!messageFeed?.messages.length) {
    return (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        imageStyle={{ height: 60 }}
        description={<span>{t('first-message')}</span>}
      />
    );
  }

  return (
    <>
      <div ref={bottomRef} id="messageFeedBottom" data-cy="messageFeedBottom" />
      <CommentList
        messages={messages}
        moderatorName={discussion?.moderator?.name}
        discussion={discussion}
        answerForm={answerForm}
        setAnswerForm={setAnswerForm}
        newMessageIds={newMessageIds}
        color={color}
        theme={theme}
      />
      {!infiniteIsDone && isInit && (
        <div
          ref={infiniteLoaderRef}
          data-cy="discussion-loader"
          style={{ padding: 8 }}
        >
          <LoaderSpin inline size="small" hasText={false} color={color} />
        </div>
      )}
      <style jsx>{`
        #messageFeedBottom {
          height: 1px;
        }
        .discussionForm__container {
          margin: 4px 0 16px;
        }
      `}</style>
    </>
  );
};

export default DiscussionFeed;
