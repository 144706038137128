import {
  EnterOutlined,
  LikeFilled,
  LikeOutlined,
  CrownOutlined
} from '@ant-design/icons';
import { Button } from 'antd';
import dayjs from 'dayjs';
import hexToRgba from 'hex-to-rgba';
import Cookies from 'js-cookie';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import Linkify from 'react-linkify';
import { getDisplayName } from '../../utils/functions/discussion';
import { useLikeMessage } from '../../utils/store/discussion';

dayjs.locale('fr'); // use locale globally
dayjs().locale('fr').format();

const Comment = ({
  messageId,
  content,
  datetime,
  likes,
  isChild = false,
  setAnswerForm,
  shouldAllowLike,
  shouldAllowAnswer,
  isNewMessage,
  color,
  messagesLiked = [],
  setMessagesLiked,
  message,
  isMobile,
  // t
}) => {
  const { t } = useTranslation(['common']);
  const likeMessage = useLikeMessage();

  const isMessageLiked = messagesLiked.some((item) => item === messageId);

  const handleLike = async (messageId) => {
    likeMessage({
      id: messageId,
      action: isMessageLiked ? 'unlike' : 'like'
    }).then(({ error }) => {
      if (!error) {
        const messagesLikedTmp = isMessageLiked
          ? messagesLiked.filter((item) => item !== messageId)
          : [...messagesLiked, messageId];
        setMessagesLiked(messagesLikedTmp);
        Cookies.set('messageLiked', JSON.stringify(messagesLikedTmp));
      }
    });
  };

  const author = (message) => {
    return (
      <span
        // style={{
        //   color: message?.fromModerator && hexToRgba(color, 0.7)
        // }}
      >
        {message?.fromModerator && <CrownOutlined />}{' '}
        {getDisplayName(message, t)}
      </span>
    );
  };

  const buttonStyles = {
    color,
    height: 'inherit',
    width: 'inherit',
    fontSize: isMobile ? 14 : 10,
    fontWeight: 500,
    padding: 0,
    marginRight: 8,
    display: 'inline-flex',
    alignItems: 'center'
  };

  return (
    <section
      id={messageId}
      className="comment"
      data-cy={isChild ? 'comment-child' : 'comment'}
    >
      <p className="comment__content">
        {isChild && <EnterOutlined style={{ transform: 'scaleX(-1)' }} />}
        <span className="author" data-cy="author">
          {author(message)}
        </span>
        <span className="content" data-cy="contentMessage">
          <Linkify
            componentDecorator={(decoratedHref, decoratedText, key) => (
              <a target="blank" href={decoratedHref} key={key} >
                {decoratedText}
              </a>
            )}
          >
            {content}
          </Linkify>
        </span>

        <span className="datetime" data-cy="dateTime">
          {dayjs(datetime).fromNow()}
        </span>

        <div className="container-meta">
          {shouldAllowLike && (
            <Button
              data-cy="discussion-likeButton"
              type="link"
              onClick={() => handleLike(messageId)}
              style={{
                ...buttonStyles
              }}
              icon={
                isMessageLiked ? (
                  <LikeFilled style={{ fontSize: isMobile ? 14 : 12 }} />
                ) : (
                  <LikeOutlined style={{ fontSize: isMobile ? 14 : 12 }} />
                )
              }
            >
              {likes !== 0 && <span className="like">{likes}</span>}
              {isMobile && <>{isMessageLiked ? t('unlike') : t('like')}</>}
            </Button>
          )}
          {!isChild && shouldAllowAnswer && (
            <Button
              data-cy="answerButton"
              type="link"
              onClick={() => setAnswerForm(messageId)}
              style={{
                ...buttonStyles,
                linkTransform: 'uppercase'
              }}
            >
              {t('reply')}
            </Button>
          )}
        </div>
      </p>
      <style jsx>{`
        .comment {
          font-size: ${isMobile ? 16 : 13}px;
          padding-left: ${isChild ? '8px' : '0'};
        }
        .comment__content {
          margin: 0;
        }
        .container-meta {
          display: inline-flex;
          align-items: center;
        }
        .content {
          opacity: 0.7;
          margin-right: 8px;
        }
        .content > a:hover {
        }
        .author {
          text-transform: uppercase;
          font-weight: 600;
          margin-right: 8px;
        }
        .datetime {
          font-size: ${isMobile ? '12px' : '10px'};
          opacity: 0.5;
          margin-right: 8px;
        }
        .like {
          margin-right: ${isMobile ? '8px' : '0'};
        }
      `}</style>
    </section>
  );
};

export default memo(Comment);
