import { useQuery, useMutation } from '@apollo/client';
import {
  GET_DISCUSSION_PROPERTIES,
  GET_DISCUSSION_MESSAGES_PUBLIC,
  GET_DISCUSSION_MESSAGES_ANIMATEUR,
  GET_DISCUSSION_PRESET,
  GET_MESSAGE_ANIMATOR_FEED
} from '../queries/discussion';
import { CREATE_MESSAGE, LIKE_MESSAGE } from '../mutations/discussion';
import useHeaders from '../hooks/useHeaders';

export const GetDiscussionProperties = (discussionId, webTvId, playlistId, skip) => {
  const headers = useHeaders();
  const { data, loading, error, subscribeToMore } = useQuery(
    GET_DISCUSSION_PROPERTIES,
    {
      variables: {
        id: discussionId,
        playlistId,
        webTvId

      },
      context: {
        headers
      },
      fetchPolicy: 'network-only',
      skip: skip || !headers['authorization-uuid']
    }
  );

  const availableData = data && data.discussion;
    
  return {
    data: availableData,
    loading,
    error: error?.graphQLErrors[0].extensions,
    subscribeToMore
  };
};

export const GetDiscussionPreset = (discussionId, skip) => {
  const headers = useHeaders();
  const { data, loading } = useQuery(GET_DISCUSSION_PRESET, {
    variables: {
      id: discussionId
    },
    context: {
      headers
    },
    fetchPolicy: 'network-only',
    skip: skip || !headers['authorization-uuid']
  });

  return {
    preset: data?.discussion?.preset,
    loading
  };
};

export const GetDiscussionPublicMessages = (discussionId, handleCompleted, playlistId, webTvId) => {
  const headers = useHeaders();
  const { data, loading, error, fetchMore, subscribeToMore } = useQuery(
    GET_DISCUSSION_MESSAGES_PUBLIC,
    {
      variables: {
        discussionId,
        offset: 0,
        limit: 20,
        playlistId,
        webTvId
      },
      skip: !headers['authorization-uuid'],
      context: {
        headers
      },
      onCompleted: (data) => handleCompleted(data)
    }
  );

  if (error) {
    // throw new Error(error);
  }
  const availableData = data && data?.messageFeed;
  return {
    messageFeed: availableData,
    loading,
    subscribeToMore,
    fetchMore
  };
};

export const GetDiscussionAnimateurMessages = (discussionId) => {
  const { data, loading, error, refetch,subscribeToMore} = useQuery(
    GET_DISCUSSION_MESSAGES_ANIMATEUR,
    {
      variables: {
        id: discussionId
      },
      // fetchPolicy: 'no-cache',
      skip: !discussionId
    }
  );

  const availableData = data?.messageAnimateurFeed.messages;

  return {
    messages: availableData,
    loading,
    refetch,
    subscribeToMore,
    error
  };
};

export const GetMessageToAnimator = (messageToAnimatorId) => {
  const { data, loading, error, refetch,subscribeToMore} = useQuery(
    GET_MESSAGE_ANIMATOR_FEED,
    {
      variables: {
        messageToAnimatorId
      },
      // fetchPolicy: 'no-cache',
      skip: !messageToAnimatorId
    }
  );

  const availableModeratorData = data?.messageToAnimator.messages;

  return {
    messageToAnimator: availableModeratorData,
    loading,
    refetch,
    subscribeToMoreMessageToAnimator: subscribeToMore,
    error
  };
};
/** ************ */
/** * Mutations ** */
/** ************ */

export const useCreateMessage = () => {
  const headers = useHeaders();
  const [createMessage, { loading }] = useMutation(CREATE_MESSAGE, {
    context: {
      headers
    }
  });
  return {
    loading,
    createMessage: async (variables) => {
      try {
        const { data } = await createMessage({
          variables
        });
        return {
          data
        };
      } catch (error) {
        return {
          error
        };
      }
    }
  };
};

export const useLikeMessage = () => {
  const headers = useHeaders();
  const [likeMessage] = useMutation(LIKE_MESSAGE, {
    context: {
      headers
    }
  });
  return async (variables) => {
    try {
      const { data } = await likeMessage({ variables });
      return {
        data
      };
    } catch (error) {
      return {
        error
      };
    }
  };
};
