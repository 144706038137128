import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { discussionState } from '../../recoil/discussion';
import { privacyTypeState } from '../../recoil/privacyType';
import useTabHeight from '../../utils/hooks/useTabHeight';
import { GetDiscussionProperties } from '../../utils/store/discussion';
import { GET_UPDATED_DISCUSSION } from '../../utils/subscription/discussion';
import Confidentiality from '../Confidentiality';
import LoaderSpin from '../LoaderSpin';
import DiscussionFeed from './DiscussionFeed';
import DiscussionForm from './DiscussionForm';
import IconQA from './IconQA';
import { getPrivacyType } from '../../utils/functions/confidentiality';

const Discussion = function ({
  discussionId,
  discussionFromServer = false,
  errorFromServer = false,
  webTvId,
  playlistId,
  theme,
  color,
  isEmbed,
}) {
  const [discussion, setDiscussion] = useState(discussionFromServer);
  const [error, setError] = useState();
  const [formVisible, setFormVisible] = useState(false);
  const [answerForm, setAnswerForm] = useState();
  const setPrivacyType = useSetRecoilState(privacyTypeState);
  const screens = useBreakpoint();
  const [isQAndA, setIsQAndA] = useRecoilState(discussionState);
  const [formHeight, setFormHeight] = useState();
  const { discussionFeedHeight, discussionFormHeight } = useTabHeight();

  const router = useRouter();

  const {
    data,
    loading,
    error: apolloError,
    subscribeToMore
  } = GetDiscussionProperties(discussionId, webTvId, playlistId /*, skip*/);

  const { ref, inView } = useInView({
    root: null,
    threshold: 0.05
  });

  useEffect(() => {
    setIsQAndA(discussion?.preset === 'QANDA');
    const privacity = getPrivacyType({
      content: discussion?.media || discussion?.live
    });
    setPrivacyType(privacity);
  }, [discussion]);

  useEffect(() => {
    if (!subscribeToMore || loading) return;
    const unsubscribe = subscribeToMore({
      document: GET_UPDATED_DISCUSSION,
      variables: {
        id: discussionId
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        return {
          ...prev,
          discussion: { ...prev.discussion, ...subscriptionData.discussion }
        };
      }
    });
    return () => unsubscribe();
  }, [subscribeToMore, loading, discussionId]);

  useEffect(() => {
    if (!data) return;
    setDiscussion(data);
    setError(null);
  }, [data, router]);

  useEffect(() => {
    if (!apolloError) return;
    setError(apolloError);
  }, [apolloError]);

  useEffect(() => {
    if (answerForm) {
      setFormVisible(false);
    } else {
      setFormVisible(inView);
    }
  }, [inView, answerForm]);

  if (loading && !discussionFromServer) return <LoaderSpin />;
  if (error && router.pathname === '/questionnaire/[discussionId]') {
    return (
      <Confidentiality
        errorData={error}
        contentId={discussionId}
        contentType="discussion"
      />
    );
  }

  return (
    <>
     <div id="feedContainer" className="container" ref={ref}>
      <h1>Questions et commentaires</h1>
      <div className="feedContainer" ref={ref}>
        {discussion.shouldShowMessages && (
          <DiscussionFeed
            discussion={discussion}
            answerForm={answerForm}
            setAnswerForm={setAnswerForm}
            color={color}
            theme={theme}
            webTvId={webTvId}
            playlistId={playlistId}
          />
        )}

      </div>
      <div className="form" id="discussionForm" style={{ zIndex: 2 }}>
        <DiscussionForm discussion={discussion} theme={theme} setFormHeight={setFormHeight} />
      </div>
        {/* {isQAndA && !discussion.shouldShowMessages && (
          <div className="qa__container">
            <IconQA color={color} />
            <p>{discussion?.placeholder}</p>
          </div>
        )} */}
      </div>

      <style jsx>{`
        .container {
          position: relative;
          height: 435px;
          border: 5px solid #1d89b0;
          padding: 0px 10px 20px 10px;
          // background-color: #1d89b0;
          max-height: 840px;
          max-width: 430px;
        }
        .container h1 {
          color: #000;
          font-size: 1.3rem;
        }

        .feedContainer {
          height: calc(100% - ${formHeight}px);
          overflow-x: ${screens.md ? 'auto' : 'initial'};
          padding-right: ${screens.md ? '16px' : 0};
          padding-bottom: ${screens.md ? '0' : formHeight}px;
        }
        .form {
          position: ${screens.md ? 'absolute' : 'fixed'};
          background: ${theme === 'dark' ? '#252525' : '#fff'};
          bottom: 0;
          left: 0;
          width: 100%;
          opacity: ${formVisible ? '1' : '0'};
          transition: opacity .2s;
          pointer-events: ${formVisible ? 'initial' : 'none'};
          padding: ${screens.md ? '0' : '16px'}
      `}</style>
      <style global jsx>{`
        .qa__container svg {
          max-width: 240px;
          max-height: 240px;
          opacity: 0.8;
        }
      `}</style>
    </>
  );
};

export default Discussion;
